'use client';

import { useEffect } from 'react';

import { Log } from '@molecula-monorepo/common.utilities/src/Log';

export default function GlobalError({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    useEffect(() => {
        new Log('GlobalError').error(error);
    }, [error]);

    return (
        <html lang="en">
            <body>
                <h2>Something went wrong!</h2>
                <button type="button" onClick={() => reset()}>
                    Try again
                </button>
            </body>
        </html>
    );
}
